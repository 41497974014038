import { GET_ALL_STATE_LIST_FOR_HOLIDAY_SUCCESS, GET_ALL_STATE_LIST_FOR_HOLIDAY_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getAllStateListForHolidaySuccess = (data) => {
  return {
    type: GET_ALL_STATE_LIST_FOR_HOLIDAY_SUCCESS,
    payload: data,
  };
};

const getAllStateListForHolidayFailure = (data) => {
  return {
    type: GET_ALL_STATE_LIST_FOR_HOLIDAY_FAILURE,
    payload: data,
  };
};

export const getStateListForHoliday = () => {
  return async (dispatch, getState) => {
    // const { error, result } = await getApi(`/get_state_for_manage_holiday`);
    const { error, result } = await getApi(`/get_all_statelist`);
    if (!error) {
      return dispatch(getAllStateListForHolidaySuccess(result));
    }
    return dispatch(getAllStateListForHolidayFailure(result));
  };
};
